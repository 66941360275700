<template>
  <v-row>
    <v-col cols="12" md="9" lg="7" class="mx-auto my-5" 
           v-if="$store.state.app.data.config.length > 0">
      <v-card class="pa-5">
        <v-card-text v-html="nl2br($store.state.app.data.config[0].text)">
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'cgv',
  data: () => ({
  }),  
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/cgv')
    await this.$store.dispatch('app/fetchEntities', { entityType: "config", query: { key: "CGV" } })
  },
  methods: {
      nl2br: function(str, is_xhtml){
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
        let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
        return newStr
      },
  },
  computed:{
  }
    
};
</script>
